import React from "react";

import Lottie from "lottie-react";

import loader from "../assets/loader/Loader.svg";

import Notification from "./Notification";

import loaderAnimation from "../assets/loader/66265-loader.json";

export default function Loading() {
  return (
    <Notification
      animation={<Lottie animationData={loaderAnimation} loop={true} />}
      image={loader}
      title={"Estamos procesando tu solicitud"}
      isAnimation={true}
    />
  );
}
