import React from "react";

import TextField from "@material-ui/core/TextField";
import { useField } from "formik";

import themeKym from "../../utils/themes/ThemeKym";

const Cedula = ({ props }) => {
  const classesKym = themeKym();
  const [field] = useField(props.name);

  const configTextField = {
    ...field,
    placeholder: props.placeholder,
    label: props.label,
    style: props.style,
    maxLength: props.maxLength,
    minLength: props.minLength,
    name: props.name,
    id: props.id,
    title: props.title,
    readOnly: props.readOnly,
  };
  configTextField.value = configTextField.value ?? "";

  const onlyNumbersCedula = (e) => {
    let x = e.target.value;

    if (props.dash === true) {
      x = x.replace(/(?!-)[\D]/g, "");

      if (x.indexOf("-") > 0 && x.indexOf("-") < x.lastIndexOf("-")) {
        x = x.substring(0, x.length - 1);
      }
    } else {
      x = x.replace(/\D/g, "");
    }

    e.target.value = x;

    field.onChange(e);
  };

  return (
    <TextField
      variant="outlined"
      {...configTextField}
      className={classesKym[`${props.className}`]}
      data-testid={`${props.id}-${props.className}`}
      onChange={(e) => onlyNumbersCedula(e)}
      inputProps={{ minLength: props.minLength, maxLength: props.maxLength }}
    />
  );
};

export default Cedula;
