import { makeStyles } from "@material-ui/core/styles";

const themeNotification = makeStyles(() => ({
  staticImage: {
    display: "flex",
    justifyContent: "center",
    marginTop: "63px !important",
    "& img": {
      width: "165px !important",
      height: "93px !important",
      marginBottom: "15px !important",
      marginTop: "15px !important",
    },
  },
  animatedImage: {
    display: "flex",
    justifyContent: "center",
    marginTop: "63px !important",
    "& img": {
      width: "95px !important",
      height: "95px !important",
      marginBottom: "15px !important",
      marginTop: "17px !important",
      position: "absolute",
    },
    "& div": {
      width: "130px",
      height: "130px",
    },
  },
  imageError: {
    position: "absolute",
    width: "149px",
    height: "160.22px",
    left: "115px",
    top: "120px",
  },
  titleContainer: {
    textAlign: "center",
    marginBottom: "5px !important",
    marginTop: "39px !important",
    "& p": {
      fontFamily: "Readex Pro !important",
      fontWeight: "700 !important",
      fontSize: "20px !important",
      lineHeight: "30px !important",
      letterSpacing: "0.25px !important",
    },
  },
  titleContainerError: {
    textAlign: "center",
    marginBottom: "5px !important",
    marginTop: "300px !important",
    "& p": {
      fontFamily: "Readex Pro !important",
      fontWeight: "500 !important",
      fontSize: "20px !important",
      lineHeight: "25px !important",
      letterSpacing: "0.25px !important",
    },
  },
  messageContainer: {
    textAlign: "center",
  },
  message: {
    fontFamily: "Readex Pro !important",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.25px !important",
  },
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
    gap: "16px",
    position: "absolute",
    width: "311px",
    height: "44px",
    left: "36px",
    top: "682px",
    background: "#363853",
    borderRadius: "16px",
  },
}));

export default themeNotification;
