import { Stack } from "@mui/material";

const byStack = ({ classesKym, classesForm, children }) => {
  return (
    <Stack
      direction="row"
      justifyContent="end"
      mt={10}
      className={`${classesKym.stackButtons} ${classesKym[classesForm.stackButtons]}`}
    >
      {children}
    </Stack>
  );
};

const byGrid = ({ classesKym, children }) => {
  return (
    <Stack direction="row" justifyContent="end" mt={10} className={` ${classesKym.stackButtons} `}>
      {children}
    </Stack>
  );
};
const inputsComponents = {
  stack: byStack,
  grid: byGrid,
  default: byStack,
};

const Input = ({ type, data }) => {
  let InputsComponent = inputsComponents[[type]];
  return <InputsComponent key={data.id} {...data} />;
};

const GenericCellElement = (props) => {
  let type = "default";
  if (props.type) {
    type = props.type;
  }
  return <Input type={type} data={props} />;
};

export default GenericCellElement;
