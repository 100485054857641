import { React, useState } from "react";

import TextField from "@material-ui/core/TextField";
import { useField } from "formik";

import themeKym from "../../utils/themes/ThemeKym";

const TextFieldPrefilled = (props) => {
  const e = props?.props;
  const classesKym = themeKym();
  const [onBlur_, setOnBlur] = useState(true);

  const [field] = useField(e.name);
  const configTextField = {
    ...field,
    placeholder: e.placeholder,
    label: e.label,
    id: e.id,
    title: e.title,
    readOnly: e.readOnly,
    style: e.style,
    maxLength: e.maxLength,
    minLength: e.minLength,
    name: e.name,
  };
  configTextField.value = configTextField.value ?? "";
  if (e.original_value && configTextField.value == "" && onBlur_) {
    configTextField.value = e.original_value;
  }

  const onBlur = (event) => {
    setOnBlur(true);
    if (event.target.value === "") {
      event.target.value = e.original_value;
      field.onChange(event);
    }
  };
  const onFocus = () => {
    setOnBlur(false);
  };
  return (
    <TextField
      data-testid={`${e.id}-${e.className}`}
      inputProps={{ minLength: e.minLength, maxLength: e.maxLength }}
      variant="outlined"
      {...configTextField}
      className={classesKym[`${e.className}`]}
      disabled={e?.disabled ? e.disabled : false}
      onBlur={(event) => onBlur(event)}
      onFocus={onFocus}
    />
  );
};

export default TextFieldPrefilled;
