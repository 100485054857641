export function validateObjectNotApplied(object) {
  let aux = 0;
  for (let property in object) {
    if (object[property] === "No aplica") {
      aux++;
    }
  }
  return aux === Object.keys(object).length;
}

export function validateNumber(obj) {
  const regex = /\d/;

  for (let prop in obj) {
    if (regex.test(obj[prop]) || !isNaN(Number(obj[prop]))) {
      return true;
    }
  }
  return false;
}

export function contributing(user) {
  return user["Negocio"]["facturation-information"]["ruc"] === "" ? "no" : "si";
}
