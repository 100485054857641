import React from "react";

import ErrorMessage from "./errorMessage";

import GenericInput from "../genericInput";

const Column = ({ props, errors }) => {
  return (
    <table align="center" width="100%" border="0">
      <tbody>
        <tr key={props.id}>
          {props.fields.map((field, i) => (
            <td key={i} width={field.props.width}>
              <GenericInput {...field} />
              <ErrorMessage name={field.props.name} errors={errors} />
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};
export default Column;
