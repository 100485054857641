import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";
import { useField } from "formik";

import themeKym from "../../utils/themes/ThemeKym";

const RucWithContributionFieldValidation = (props) => {
  const [onBlurState, setOnBlurState] = useState(true);
  const data = props?.props;
  const [field] = useField(data.name);
  const classesKym = themeKym();

  const configTextField = {
    ...field,
    placeholder: data.placeholder,
    label: data.label,
    id: data.id,
    title: data.title,
    readOnly: data.readOnly,
    style: data.style,
    name: data.name,
    disabled: data.original_value !== "",
  };

  configTextField.value = configTextField.value ?? "";
  if (data.original_value && configTextField.value == "" && onBlurState) {
    configTextField.value = data.original_value;
  }

  const onBlur = (event) => {
    setOnBlurState(true);
    if (event.target.value === "") {
      event.target.value = data.original_value;
      field.onChange(event);
    }
  };
  const onFocus = () => {
    setOnBlurState(false);
  };

  return (
    <TextField
      data-testid={`${data.id}-${data.className}`}
      inputProps={{ minLength: data.minLength, maxLength: data.maxLength }}
      variant="outlined"
      {...configTextField}
      className={classesKym[`${data.className}`]}
      onBlur={(event) => onBlur(event)}
      onFocus={onFocus}
    />
  );
};

export default RucWithContributionFieldValidation;
