import axios from "axios";

const axiosClient = axios.create();

axiosClient.defaults.baseURL = process.env.REACT_APP_DOMAIN;

axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  "x-api-key": process.env.REACT_APP_KEY,
  Accept: "application/json",
};

//All request will wait 30 seconds before timeout
axiosClient.defaults.timeout = 30000;

export default axiosClient;
