const events = {
  business: {
    load: "PREVIEW_KYA_IDPDV",
    next: "KYA_IDPDV_NEXT",
    error: "KYA_IDPDV_FAIL",
  },
  form: {
    load: "PREVIEW_KYA_INFO",
    next: "KYA_INFO_NEXT",
  },
  otp: {
    load: "PREVIEW_KYA_OTP",
    next: "KYA_OTP_NEXT",
    error: "KYA_OTP_FAIL",
  },
  documents: {
    load: "PREVIEW_KYA_DOCS",
    next: "KYA_DOCS_NEXT",
    error: "KYA_DOCS_FAIL",
  },
  confirm: {
    load: "PREVIEW_KYA_SUMMARY",
    next: "KYA_SUMMARY_NEXT",
    error: "KYA_SUMMARY_FAIL",
  },
};

const journey = "Onboarding";
const product_group = "Lending";
const product_name = "Working_Capital";
const button_name = "Siguiente";

const preview = {
  journey: journey,
  product_group: product_group,
  product_name: product_name,
};

const properties = {
  PREVIEW_KYA_IDPDV: {
    page: "WC_KYA_IDPDV",
    ...preview,
  },
  KYA_IDPDV_NEXT: {
    page: "WC_KYA_IDPDV",
    ...preview,
    button_name: button_name,
  },
  KYA_IDPDV_FAIL: {
    page: "WC_KYA_IDPDV",
    ...preview,
  },
  PREVIEW_KYA_INFO: {
    page: "WC_KYA_Info",
    ...preview,
  },
  KYA_INFO_NEXT: {
    page: "WC_KYA_Info",
    ...preview,
    button_name: button_name,
  },
  PREVIEW_KYA_DOCS: {
    page: "WC_KYA_Docs",
    ...preview,
  },
  KYA_DOCS_NEXT: {
    page: "WC_KYA_Docs",
    ...preview,
    button_name: button_name,
  },
  KYA_DOCS_FAIL: {
    page: "WC_KYA_Docs",
    ...preview,
  },
  PREVIEW_KYA_SUMMARY: {
    page: "WC_KYA_Summary",
    ...preview,
  },
  KYA_SUMMARY_NEXT: {
    page: "WC_KYA_Summary",
    ...preview,
    button_name: button_name,
  },
  KYA_SUMMARY_FAIL: {
    page: "WC_KYA_Summary",
    ...preview,
  },
  PREVIEW_KYA_OTP: {
    page: "WC_KYA_Otp",
    ...preview,
  },
  KYA_OTP_NEXT: {
    page: "WC_KYA_Otp",
    ...preview,
    button_name: button_name,
  },
  KYA_OTP_FAIL: {
    page: "WC_KYA_Otp",
    ...preview,
  },
};

export function GetWCProperties(stageName, event) {
  switch (stageName) {
    case "Negocio":
      return {
        event: events.business[event],
        properties: properties[events.business[event]],
      };
    case "Formulario":
      return {
        event: events.form[event],
        properties: properties[events.form[event]],
      };
    case "validate-otp":
      return {
        event: events.otp[event],
        properties: properties[events.otp[event]],
      };
    case "Documentos":
      return {
        event: events.documents[event],
        properties: properties[events.documents[event]],
      };
    case "Confirmacion":
      return {
        event: events.confirm[event],
        properties: properties[events.confirm[event]],
      };
    default:
      break;
  }
  return { event: "", properties: {} };
}
