import React from "react";

import { Grid } from "@mui/material";

import { default as btn } from "./inputs/button";
import ButtonsOTP from "./inputs/buttonsOtp";
import Submit from "./inputs/submit";

const buttonsComponents = {
  button: btn,
  submit: Submit,
  buttonsOTP: ButtonsOTP,
};

const orderComponents = {
  grid: Grid,
};
const Button = ({
  xs,
  order,
  key,
  type,
  data,
  btnBefore,
  btnNext,
  btnResendOTP,
  submit,
  dirty,
  isValid,
  messageOTP,
  pinComplete,
  btnNextLegacy,
  btnLegacyValidateOTP,
}) => {
  const ButtonsComponent = buttonsComponents[[type]];
  if (!ButtonsComponent) {
    return null;
  }
  const OrderComponent = orderComponents[[order]];

  let config = {
    item: true,
    xs: xs,
  };

  return (
    <React.Fragment>
      {!OrderComponent ? (
        <div key={key}>
          <ButtonsComponent
            btn={data}
            btnBefore={btnBefore}
            btnNext={btnNext}
            btnResendOTP={btnResendOTP}
            btnLegacyValidateOTP={btnLegacyValidateOTP}
            btnNextLegacy={btnNextLegacy}
            submit={submit}
            dirty={dirty}
            isValid={isValid}
            messageOTP={messageOTP}
            pinComplete={pinComplete}
          />
        </div>
      ) : (
        <OrderComponent {...config}>
          <ButtonsComponent
            btn={data}
            btnBefore={btnBefore}
            btnNext={btnNext}
            btnResendOTP={btnResendOTP}
            btnLegacyValidateOTP={btnLegacyValidateOTP}
            submit={submit}
            dirty={dirty}
            isValid={isValid}
            messageOTP={messageOTP}
            pinComplete={pinComplete}
          />
        </OrderComponent>
      )}
    </React.Fragment>
  );
};

const GenericButton = ({
  xs,
  order,
  key,
  props,
  btnBefore,
  btnNext,
  btnResendOTP,
  btnLegacyValidateOTP,
  submit,
  dirty,
  isValid,
  messageOTP,
  pinComplete,
  btnNextLegacy,
}) => {
  return (
    <Button
      xs={xs}
      order={order}
      key={key}
      type={props.type}
      data={props}
      btnBefore={btnBefore}
      btnNext={btnNext}
      btnResendOTP={btnResendOTP}
      btnLegacyValidateOTP={btnLegacyValidateOTP}
      btnNextLegacy={btnNextLegacy}
      submit={submit}
      dirty={dirty}
      isValid={isValid}
      messageOTP={messageOTP}
      pinComplete={pinComplete}
    />
  );
};

export default GenericButton;
