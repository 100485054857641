import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Avatar, Box, Grid, Modal, Stack, Typography } from "@mui/material";
import { Formik, Form as Formx } from "formik";
import PropTypes from "prop-types";

import FooterContainer from "./footerContainer";
import GenericButton from "./genericButton";
import GenericInput from "./genericInput";
import BasicTable from "./inputs/basicTable";
import ButtonAdd from "./inputs/buttonAdd";
import CardForm from "./inputs/cardForm";
import ErrorMessage from "./inputs/errorMessage";
import StepByStep from "./stepByStep";

import { Icons } from "../utils/icons-material";
import { getYup, schema } from "../utils/yup";

const FormV2 = ({
  form,
  dataForms,
  btnNext,
  btnBefore,
  steppers,
  isBack,
  deleteTable,
  saveTable,
  onChangeRadio,
  msisdn,
  phone,
}) => {
  let validates = {};
  let initialValue = {};
  const section = form.section;
  const footer = form.footer ? form.footer.fields : [];
  const btns = form.actionsButtons;
  const [showModal, setShowModal] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [rowEdit, setRowEdit] = useState({});

  /**
   *
   * Add validations yup schema
   *
   **/
  //Find fields and add validates
  const findFieldsValidationsV2 = (fields) => {
    fields.forEach(({ props, validations, data }) => {
      if (props.type === "column") {
        findFieldsValidationsV2(props.fields);
      } else {
        initialValue[props.name] = props.value ?? "";
        validates[props.name] = schema(
          validations,
          props.type,
          data ?? [].length > 0 ? data.slice(1).map((x) => x.label) : []
        );
      }
    });
  };

  /**
   *
   * First load
   *
   **/
  //Styles into formik
  const styles = {
    TitleForm: {
      fontFamily: "Roboto",
      fontWeight: 600,
      lineHeight: "35px",
      color: "#404A56",
    },
    SubtitleForm: {
      fontFamily: "Roboto",
      fontWeight: 400,
      lineHeight: "30px",
      color: "#404A56",
      marginBottom: "50px",
    },
    TitleSection: {
      fontFamily: "Roboto",
      fontSize: 18,
      fontWeight: 600,
      lineHeight: "32px",
      color: "#4C5866",
    },
    AvatarForm: {
      backgroundColor: "#FFC70D",
      color: "#FFFFFF",
    },
    SubtitleSection: {
      fontFamily: "Roboto",
      fontWeight: 400,
      lineHeight: "17px",
      color: "#404A56",
      marginLeft: "59px",
      marginBottom: "10px",
      marginTop: 0,
    },
    DescriptionSection: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "17px",
      color: "#404A56",
      marginLeft: "59px",
      fontStyle: "italic",

      // fontFamily: "Inter",
      letterSpacing: "0px",
    },
  };
  //validations and values
  section.forEach((sec) => {
    findFieldsValidationsV2(sec.fields);
  });
  //Validations for foot input
  if (footer.length > 0) {
    footer.forEach(({ props, validations }) => {
      initialValue[props.name] = "";
      validates[props.name] = schema(validations, props.type, []);
    });
  }

  /**
   *
   * funtions for formik
   *
   **/
  //Adjust size for column
  const mdAutomatic = (numSelect, multiple) => {
    if (multiple) return 4;

    return 10 / (numSelect >= 3 ? 3 : numSelect);
  };
  //Send Err into inputs
  const addErr = (field, err) => {
    initialValue = {};
    section.forEach((sec) => {
      findFieldsValidationsV2(sec.fields);
      findFieldsValueWithRadio(sec);
    });

    if (footer.length > 0) {
      footer.forEach(({ props, validations }) => {
        initialValue[props.name] = "";
        validates[props.name] = schema(validations, props.type, []);
      });
    }

    for (let i in err) {
      if (!initialValue.hasOwnProperty(i)) {
        delete err[i];
      }
    }
    field.errors = err;
    return field;
  };

  /**
   *
   * Values into Formik
   *
   **/
  //Initializate values from select into formik
  const findFieldsValue = (fields) => {
    fields.forEach(({ props }) => {
      if (props.type === "column") {
        findFieldsValue(props.fields);
      } else {
        initialValue[props.name] = props.value ?? "";
      }
    });
  };
  //Filter field from radio and initializate values into formik
  const findFieldsValueWithRadio = (sec) => {
    if (sec.hasOwnProperty("radio")) {
      if (initialValue[sec.radio.name] === sec.radio.value) {
        findFieldsValue(sec.fields);
      }
    } else {
      findFieldsValue(sec.fields);
    }
  };
  //Get initial values from modal or form
  const getInitialValue = () => {
    if (JSON.stringify(rowEdit) != "{}") return rowEdit;
    else return initialValue;
  };

  /**
   *
   * Functions into inputs
   *
   **/
  //Function into onchangeRadio
  const eventRadioButton = (name, value) => {
    initialValue = {};
    initialValue[name] = value;
    onChangeRadio(value);
    section.forEach((sec) => {
      findFieldsValidationsV2(sec.fields);
      findFieldsValueWithRadio(sec);
    });
  };
  //Function into onChange select relation with other select
  const OnDependenceV2 = (item, value) => {
    let dependenceV2 = item.props.dependence;
    let isFirstItem = value === item.data[0].label;

    if (dependenceV2) {
      let sec = section.find((obj) => {
        return obj.sectionName === dependenceV2.split(".")[0];
      });

      let select = sec.fields.find(({ props }) => {
        return props.name === dependenceV2.split(".")[1];
      });
      if (select.data[0].dependence === undefined) {
        let dependV2 = item.data.find((x) => x.defaulValue.toUpperCase() === value.toUpperCase());
        let data = select.data.find((x) => x.defaulValue.toUpperCase() === dependV2.dependence.toUpperCase());
        item.setFieldValue(`${select.props.name}`, data.label);
      } else {
        item.setFieldValue(`${select.props.name}`, select.data[0].label);
        if (isFirstItem) {
          item.setFieldValue(`data_${select.props.name}`, select.data);
        } else {
          let dataV2 = select.data.filter(
            (x) => x.dependence.toUpperCase() === value.toUpperCase() || x.dependence === ""
          );
          item.setFieldValue(`data_${select.props.name}`, dataV2);
        }
      }
    }
  };

  /**
   *
   * Functions Multiple form
   *
   **/
  //Hidden form with add button at the end
  const btnHiden = (name, resetForm) => {
    resetForm();
    setShowForm(false);
  };
  //Show button with add button at the end
  const btnShow = () => {
    setShowForm(true);
  };

  /**
   *
   * Functions Modal
   *
   **/
  //Make reset formik, reset values edit, close modal
  const handleClose = (resetForm) => {
    setShowModal(false);
    setRowEdit({});
    resetForm();
  };
  //Show modal
  const btnModal = () => {
    setShowModal(true);
  };

  /**
   *
   * Functions basic Table
   *
   **/
  //Open modal and initializate values in modal
  const editTable = (row, index) => {
    setShowModal(true);
    row.id = index;
    setRowEdit(row);
  };

  /**
   *
   * Builds components into formik
   *
   **/
  //Build body of formik (Section or basic table)
  const sectionModalBuild = (sec, j, values) => {
    let fields = undefined;
    if (sec.form === "Modal") {
      fields = addBasicTable(sec, j, values);
    } else {
      fields = addInputsFields(sec, values);
    }

    return sectionBuild(sec, j, values, fields);
  };
  //Build Body section
  const sectionBuild = (sec, j, values, fields, showButton) => {
    /*data use for hide o show form according to function multiple*/
    if (
      /*validate if contain field radio and if exist then validate that have same name in field for hide or show*/
      /*Or if for skip validation of radio*/
      (sec.hasOwnProperty("radio") && initialValue[sec.radio.name] === sec.radio.value) ||
      !sec.hasOwnProperty("radio")
    ) {
      return (
        <React.Fragment key={j}>
          <Grid id={"sec-" + sec.id} key={"sec-" + j} item xs={11} md={mdAutomatic(section.length, sec.cards)}>
            <Stack direction="row" spacing={2} mb={3}>
              <Avatar style={styles.AvatarForm}>{Icons(sec.icon)}</Avatar>
              <Typography align="left" style={styles.TitleSection} gutterBottom component="div">
                {sec.title}
              </Typography>
            </Stack>

            <Grid container spacing={2} mt={1}>
              <Grid
                id={"sub-" + sec.id}
                key={"sub-" + j}
                item
                xs={11}
                md={mdAutomatic(section.length, sec.cards)}
                sx={{ paddingTop: "0 !important" }}
              >
                <Typography
                  align="left"
                  variant="h5"
                  style={styles.SubtitleSection}
                  sx={{
                    fontSize: {
                      md: 17,
                      sm: 14,
                    },
                  }}
                  gutterBottom
                  component="div"
                  mt={3}
                >
                  {sec.subtitle}
                </Typography>
                <Typography
                  align="left"
                  variant="h5"
                  style={styles.DescriptionSection}
                  sx={{
                    fontSize: {
                      md: 15,
                      sm: 12,
                    },
                  }}
                  gutterBottom
                  component="div"
                  mt={3}
                >
                  {sec.description}
                </Typography>
              </Grid>
              {sec.gridCol && (
                <Grid id={"card-" + sec.id} key={"card-" + j} item xs={11} md={12}>
                  {addGridCol(sec, showButton)}
                </Grid>
              )}
            </Grid>

            {showForm && fields}
            {addButton(sec, values)}
          </Grid>
        </React.Fragment>
      );
    }
  };
  //Build basic table
  const addBasicTable = (sec, j, values) => {
    return (
      <>
        <BasicTable
          data={sec.table.data}
          columns={sec.table.columns}
          handleEdit={editTable}
          handleDelete={deleteTable}
        />
        <Modal className={"backgroundModal"} open={showModal}>
          <Box sx={{ p: 4 }} className="modal-box">
            <CloseIcon className="icon-close-modal" onClick={() => handleClose(values.resetForm)} />
            {sectionBuild(sec, j, values, addInputsFields(sec, values), false)}
          </Box>
        </Modal>
      </>
    );
  };
  //Build form in field and buttons
  const addInputsFields = (sec, values) => {
    return (
      <div style={{ width: "450px" }}>
        {sec.fields.map((field) => (
          <div key={field.props.id} style={{ marginTop: "20px" }}>
            <GenericInput
              {...addErr(field, values.errors)}
              {...values}
              eventRadioBtn={eventRadioButton}
              onchange={OnDependenceV2}
              phone={phone}
            />
            <ErrorMessage name={field.props.name} errors={values.errors} />
          </div>
        ))}
        <div className="buttonsInputFields">
          {sec.buttons &&
            sec.buttons.map((btn) => (
              <GenericButton
                key={btn.id}
                props={btn}
                btnBefore={() => handleClose(values.resetForm)}
                btnNext={saveTable}
                dirty={values.dirty}
                isValid={values.isValid}
                submit={values.submitForm}
              />
            ))}
        </div>
      </div>
    );
  };
  //Add component in left column in formik
  const addGridCol = (sec, showButton = true) => {
    if (sec.gridCol.cards) return <CardForm data={sec.gridCol.cards} />;

    if (showButton) return <GenericButton props={sec.gridCol.button} btnNext={btnModal} />;
  };
  //Add button at the bottom of the form
  /**
   * input
   * input
   * button
   */
  const addButton = (sec, val) => {
    /*validate if de section if multiple form*/
    if (sec.form === "Multiple") {
      return (
        <ButtonAdd
          show={showForm}
          props={sec.button}
          btnHiden={btnHiden}
          btnShow={btnShow}
          touched={val.touched}
          fields={sec.fields}
          submitForm={val.submitForm}
          resetForm={val.resetForm}
        />
      );
    } else {
      return;
    }
  };

  return (
    <Formik
      initialValues={getInitialValue()}
      enableReinitialize={true}
      validationSchema={getYup(validates)}
      schema
      onSubmit={(valores, fun) => {
        let data = [];
        data = valores;

        if (showModal) {
          handleClose(fun.resetForm);
          saveTable(data);
        } else {
          btnNext(data);
        }
        return true;
      }}
    >
      {(props) => {
        const { values, setFieldValue, errors, submitForm, touched, resetForm, dirty, isValid } = props;
        return (
          <Formx className={"v2"}>
            <Grid container sx={{ height: "100%", overflowY: "auto" }}>
              {steppers && (
                <Grid id={"id_steper"} item xs={3} md={3} sx={{ paddingLeft: "5%" }}>
                  <StepByStep dataForms={dataForms} step={form}></StepByStep>
                </Grid>
              )}

              <Grid id={"id_formContainer"} item xs={9} md={9}>
                <Box>
                  <Typography
                    align="left"
                    style={styles.TitleForm}
                    sx={{
                      fontSize: {
                        md: 42,
                        sm: 25,
                      },
                    }}
                    variant="h4"
                    gutterBottom
                    component="div"
                  >
                    {form.title}
                  </Typography>
                  <Typography
                    align="left"
                    variant="h5"
                    style={styles.SubtitleForm}
                    sx={{
                      fontSize: {
                        md: 16,
                        sm: 12,
                      },
                    }}
                    gutterBottom
                    component="div"
                    mt={3}
                  >
                    {form.subtitle}
                  </Typography>
                </Box>
                {section.map((sec, j) => {
                  return sectionModalBuild(sec, j, {
                    values,
                    setFieldValue,
                    errors,
                    touched,
                    submitForm,
                    resetForm,
                    dirty,
                    isValid,
                    msisdn,
                  });
                })}
              </Grid>
            </Grid>

            <FooterContainer>
              {footer.map((field) => (
                <div key={field.props.id}>
                  <GenericInput {...addErr(field, errors)} />
                  <ErrorMessage name={field.props.name} errors={errors} />
                </div>
              ))}
            </FooterContainer>

            <Grid className="buttonsStepsContainer" container>
              <Grid item xs={3} md={3}>
                {" "}
              </Grid>
              <Grid className="buttonsStepsDiv" item xs={9} md={9}>
                {btns.map((btn) => (
                  <div key={btn.id}>
                    <GenericButton
                      props={btn}
                      btnBefore={btnBefore}
                      btnNext={btnNext}
                      dirty={dirty || isBack || (section[0]?.table?.data ?? []).length >= (section[0]?.table?.min ?? 1)}
                      isValid={isValid || (section[0]?.table?.data ?? []).length >= (section[0]?.table?.min ?? 1)}
                    />
                  </div>
                ))}
              </Grid>
            </Grid>
          </Formx>
        );
      }}
    </Formik>
  );
};
export default FormV2;

FormV2.propTypes = {
  phone: PropTypes.string,
};
