import axios from "axios";

const axiosClientMwc = axios.create();

axiosClientMwc.defaults.baseURL = process.env.REACT_APP_DOMAIN_MWC;

axiosClientMwc.defaults.headers = {
  "Content-Type": "application/json",
  "x-api-key": process.env.REACT_APP_KEY_MWC,
  Accept: "application/json",
};

//All request will wait 30 seconds before timeout
axiosClientMwc.defaults.timeout = 30000;

export default axiosClientMwc;
