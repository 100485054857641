import React from "react";

import TextField from "@material-ui/core/TextField";
import { useField } from "formik";

import themeKym from "../../utils/themes/ThemeKym";

const Ruc = ({ props }) => {
  const classesKym = themeKym();
  const [rucField] = useField(props.name);

  const configTextField = {
    ...rucField,
    placeholder: props.placeholder,
    label: props.label,
    style: props.style,
    maxLength: props.maxLength,
    minLength: props.minLength,
    name: props.name,
    id: props.id,
    title: props.title,
    readOnly: props.readOnly,
  };
  configTextField.value = configTextField.value ?? "";

  const maskRuc = (e) => {
    const size = e.target.value.length;

    if (e.target.value.length > 0) {
      e.target.value =
        e.target.value.substr(0, size - 1).replace("-", "") +
        "-" +
        e.target.value.substr(size - 1, size).replace("-", "");
    }

    rucField.onBlur(e);
    rucField.onChange(e);
  };

  const onlyNumbersRuc = (e) => {
    let x = e.target.value;
    x = x.replace(/\D/g, "");

    e.target.value = x;

    rucField.onChange(e);
  };

  return (
    <TextField
      variant="outlined"
      {...configTextField}
      className={classesKym[`${props.className}`]}
      data-testid={`${props.id}-${props.className}`}
      onBlur={maskRuc}
      onChange={onlyNumbersRuc}
      inputProps={{ minLength: props.minLength, maxLength: props.maxLength }}
    />
  );
};

export default Ruc;
