import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const themeOtp = makeStyles((theme: Theme) => ({
  subTitleOtpExtra: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "12px",
    },
  },
  boxPrincipal: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "15px",
      marginRight: "15px",
    },
  },
  messageOtp: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "30px",
      fontWeight: "600",
      fontFamily: "Roboto",
      fontStyle: "normal",
    },
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  ContentError: {
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Readex Pro",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "22px",
      letterSpacing: "0.247px",
      color: "#4C5866",

      background: "#FAEDEA",
      border: "1px solid #FAEDEA",
      borderRadius: "8px",
      height: "56px",

      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    [theme.breakpoints.up("sm")]: {
      fontFamily: "Readex Pro",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "22px",
      letterSpacing: "0.247px",
      color: "#4C5866",

      background: "#FAEDEA",
      border: "1px solid #FAEDEA",
      borderRadius: "8px",
      height: "56px",

      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  pinOtpBoxes: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "30px",
      display: "grid",
      gridAutoFlow: "column",
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "0px",
      marginTop: "20px !important",
    },
  },
  TextTimerOTP: {
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "24px",
      textAlign: "center",
      letterSpacing: "0.247px",
      color: "#404A56",
    },
    [theme.breakpoints.up("sm")]: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "20px",
      lineHeight: "24px",
      textAlign: "center",
      letterSpacing: "0.247px",
      color: "#404A56",
      marginTop: "34px",
    },
  },
  TextFieldOTP: {
    [theme.breakpoints.down("sm")]: {
      background: "#FFFFFF",
      border: "1.6px solid #CFD5DC",
      borderRadius: "8px",
      "& .MuiInputBase-input": {
        height: "25px",
        width: "15px",
        textAlign: "center",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "26px",
        lineHeight: "22px",
      },
    },
    [theme.breakpoints.up("sm")]: {
      background: "#FFFFFF",
      border: "1.6px solid #CFD5DC",
      borderRadius: "8px",
      "& .MuiInputBase-input": {
        height: "45px",
        width: "40px",
        textAlign: "center",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "26px",
        lineHeight: "22px",
      },
    },
  },
  banner: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "-50px",
    },
  },
  contentLogo: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  titleOtp: {
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "26px",
      lineHeight: "30px",
      letterSpacing: "0.150px",
      color: "#404A56",
      float: "left",
      textAlign: "center",
      display: "contents",
    },
    [theme.breakpoints.up("sm")]: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "26px",
      lineHeight: "30px",
      letterSpacing: "0.247px",
      color: "#404A56",
      float: "left",
      textAlign: "center",
      display: "contents",
    },
  },
  subTitleOtp: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "30px !important",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      color: "#404A56",
      float: "left",
      textAlign: "center",
      display: "contents",
    },
    [theme.breakpoints.up("sm")]: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "22px",
      lineHeight: "25px",
      letterSpacing: "0.247px",
      color: "#404A56",
      float: "left",
      textAlign: "center",
      display: "contents",
    },
  },
  otpCodeBorderFieldFilled: {
    border: "1px solid #FABE00 !important",
    background: "#FFFFFF",
    borderRadius: "8px",
    "& .MuiInputBase-input": {
      height: "95px",
      width: "75px",
      textAlign: "center",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "26px",
      lineHeight: "22px",
    },
  },
}));

export default themeOtp;
