import moment from "moment-timezone";

const getTimeForCountryCode = (countryCode) => {
  switch (countryCode) {
    case "py":
      return getTimeforTimeZone("America/Asuncion");
    case "hn":
      return getTimeforTimeZone("America/Tegucigalpa");
    case "pa":
      return getTimeforTimeZone("America/Panama");
    default:
      return getTimeforTimeZone("America/Asuncion");
  }
};

const getTimeforTimeZone = (timeZone) => {
  return moment().tz(timeZone).format();
};

export { getTimeForCountryCode, getTimeforTimeZone };
