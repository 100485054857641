import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from "react-input-mask";

import es from "date-fns/locale/es";
import { useField, useFormikContext } from "formik";

const DateLegalField = ({ props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  registerLocale("es", es);

  const minYear = new Date().getFullYear() - 80;
  const maxYear = new Date().getFullYear() - 18;
  const month = new Date().getMonth() + 1;
  const day = new Date().getDate();

  const minDate = new Date(minYear + "-" + month + "-" + day);
  const maxDate = new Date(maxYear + "-" + month + "-" + day);

  return (
    <DatePicker
      selected={(field.value && new Date(field.value)) || null}
      placeholderText={props.placeholder}
      showYearDropdown
      showMonthDropdown
      adjustDateOnChange
      locale="es"
      autoComplete="nope"
      dropdownMode="select"
      dateFormat="dd/MM/yyyy"
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
      customInput={<InputMask mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]} />}
      includeDateIntervals={[{ start: minDate, end: maxDate }]}
    />
  );
};

export default DateLegalField;
