import { makeStyles } from "@material-ui/core/styles";

export const paymentsTheme = makeStyles(() => ({
  congratsText: {
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#343C46",
    fontFamily: "Readex Pro",
    textAlign: "center",
  },
  title: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#343C46",
    fontFamily: "Readex Pro",
  },
  description: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#343C46",
    fontFamily: "Readex Pro",
    textAlign: "center",
  },
  label: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#343C46",
    fontFamily: "Readex Pro",
    letterSpacing: "0.25px",
  },
  value: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#343C46",
    fontFamily: "Readex Pro",
    letterSpacing: "0.25px",
  },
  boxBorder: {
    borderBottom: "1px solid #E6E6E6",
  },
  totalPaymentImage: {
    width: "80%",
  },
  checkPaymentContainer: {},
  checkPaymentImage: {
    width: "40%",
  },
}));

export const primaryButtonStyles = {
  btn: {
    type: "button",
    color: "primary",
    name: "next",
    styleClassName: "action-button",
    label: "",
  },
};

export const secondaryButtonStyles = {
  btn: {
    type: "button",
    color: "primary",
    name: "next",
    styleClassName: "action-button-outlined",
    label: "",
  },
};

export const setButtonLabel = (secondaryButtonStyles, buttonLabel) => {
  return {
    btn: {
      ...secondaryButtonStyles.btn,
      label: buttonLabel,
    },
  };
};
