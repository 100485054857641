import { Fragment, useState } from "react";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FileUploadIcon from "@mui/icons-material/UploadFile";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

import { ReactComponent as TrashIcon } from "../../assets/icon/trash.svg";

const UploadFileArea = ({ onChange }) => {
  const [file, setFile] = useState(null);

  function internalOnChange(value) {
    setFile(value.target.files[0]);
    onChange(value);
  }
  function removeFile() {
    setFile(null);
    onChange({ target: { files: [null] } });
  }

  return (
    <div
      style={{
        padding: "25px 30px",
        margin: "0 5%",
        backgroundColor: "rgba(239, 241, 243, 0.6)",
        maxWidth: "90%",
        height: "400px",
        borderRadius: "30px",
      }}
    >
      <Box
        border="2px dashed rgba(128, 143, 161, 0.5)"
        display="inline-block"
        width="100%"
        height="100%"
        borderRadius="10px"
        backgroundColor="#fff"
      >
        {!file ? (
          <Fragment>
            <div
              style={{
                textAlign: "center",
                paddingTop: "30px",
                position: "relative",
                height: "100%",
              }}
            >
              <FileUploadIcon style={{ color: "#FFC10E", fontSize: "60px" }} />
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                  paddingTop: "30px",
                  fontWeight: 600,
                  fontSize: "32px",
                  color: "#404A56",
                }}
              >
                Arrastra tu documento aquí
              </Typography>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                  paddingTop: "15px",
                  fontWeight: 500,
                  fontSize: "18px",
                  color: "#404A56",
                }}
              >
                Arrastra y suelta el documento que necesitas cargar o búscalo en tu ordenador haciendo click en el botón
                de abajo.
              </Typography>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                  paddingTop: "20px",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
              >
                <a
                  style={{
                    color: "#404A56",
                    backgroundColor: "#FFC10E",
                    padding: "12px 50px",
                    textDecoration: "none",
                    borderRadius: "4px",
                    fontWeight: 500,
                  }}
                  href="# "
                >
                  Sube un archivo
                </a>
              </Typography>
              <div
                style={{
                  display: "flex",
                  direction: "row",
                  justifyContent: "left",
                  alignItems: "end",
                  position: "absolute",
                  bottom: "25px",
                  left: "40px",
                }}
              >
                <ErrorOutlineIcon style={{ fontSize: 19 }} />
                <Typography
                  variant="h5"
                  gutterBottom
                  component="div"
                  sx={{
                    paddingTop: "20px",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#404A56",
                    margin: 0,
                  }}
                >
                  Formato/peso máximo: .pdf, .jpg o .png/250 KB
                </Typography>
              </div>
              <input
                data-testid={"InputFile"}
                id="file-id"
                required
                type="file"
                multiple
                accept="image/*"
                style={{
                  position: "absolute",
                  margin: "0",
                  padding: "0",
                  width: "100%",
                  height: "350px",
                  opacity: "0",
                  outline: "none",
                  cursor: "pointer",
                  left: 0,
                  top: 0,
                }}
                onChange={internalOnChange}
              />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div
              style={{
                textAlign: "center",
                paddingTop: "30px",
                position: "relative",
                height: "100%",
              }}
            >
              <CheckCircleOutlineIcon style={{ color: "#66BC6B", fontSize: "60px" }} />
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                  paddingTop: "30px",
                  fontWeight: 600,
                  fontSize: "32px",
                  color: "#404A56",
                }}
              >
                Su archivo se ha cargado correctamente
              </Typography>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                  paddingTop: "15px",
                  fontWeight: 500,
                  fontSize: "18px",
                  color: "#404A56",
                }}
              >
                {file.name}
              </Typography>
              <div
                style={{
                  display: "flex",
                  direction: "row",
                  justifyContent: "left",
                  alignItems: "end",
                  position: "absolute",
                  bottom: "25px",
                  left: "40px",
                }}
              >
                <ErrorOutlineIcon style={{ fontSize: 19 }} />
                <Typography
                  variant="h5"
                  gutterBottom
                  component="div"
                  sx={{
                    paddingTop: "20px",
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "#404A56",
                    margin: 0,
                  }}
                >
                  Formato/peso máximo: .pdf, .jpg o .png/10 MB
                </Typography>
                <div
                  style={{
                    display: "flex",
                    direction: "row",
                    alignItems: "end",
                    cursor: "pointer",
                  }}
                  onClick={removeFile}
                >
                  <TrashIcon data-testid={"btn-delete"} style={{ marginLeft: "80px" }} />
                  <Typography
                    variant="h5"
                    gutterBottom
                    component="div"
                    sx={{
                      paddingTop: "20px",
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#404A56",
                      margin: 0,
                    }}
                  >
                    Eliminar
                  </Typography>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Box>
    </div>
  );
};

export default UploadFileArea;
