import React from "react";

import { Button } from "@mui/material";

const Submit = ({ btn, submit, dirty, isValid }) => {
  const disabled = !(dirty && isValid) && (btn.styleClassName ?? "").includes("primary");

  const getClasses = () => {
    if (btn.styleClassName) {
      let _class = [];
      _class.push(btn.styleClassName);
      if (disabled) _class.push("merchant-disabel");
      return _class.join(" ").trim();
    }

    return "merchant-primary";
  };
  return (
    <Button
      color="primary"
      variant="contained"
      className={getClasses()}
      fullWidth
      type={btn.type}
      name={btn.name}
      onClick={submit}
    >
      {btn.label}
    </Button>
  );
};

export default Submit;
