import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core";
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import clsx from "clsx";

const StepByStep = ({ dataForms, step }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [listSteps, setListSteps] = useState([]);

  useEffect(() => {
    setActiveStep(step?.step - 1);
    setListSteps(dataForms);
  }, [dataForms, step]);

  const useStyles = makeStyles(() => ({
    root: {
      "& .MuiStepLabel-root": { padding: 0 },
      "& .MuiStepLabel-label ": {
        fontSize: "18px",
        font: "Roboto",
        fontWeight: "700 !important",
        lineHeight: "20px",
      },
      "& .Mui-active": { color: "#004FB6 !important" },
      "& .Mui-completed": { color: "#004FB6 !important" },
      "& .Mui-disabled": { color: "#CFD5DC" },
      "& .Mui-disabled > span": { borderColor: "#CFD5DC" },
      "& .MuiStepIcon-active": { color: "#004FB6" },
      "& .MuiStepIcon-completed": { color: "#66BC6B" },
      "& .MuiStepIcon-text": { fontSize: "20" },
      "& .MuiStepConnector-line": {
        borderLeftWidth: "3px",
        borderColor: "#004FB6",
      },
      "& .MuiStepConnector-vertical": { marginLeft: "15px" },
    },
    disabled: {
      color: "#CFD5DC",
    },
    active: {
      color: "#004FB6",
    },
    completed: {
      color: "#004FB6",
    },
  }));
  const classes = useStyles();
  const CustomStepIcon = (props) => {
    const { active, completed, disabled } = props;

    const stepIcons = {
      1: <CheckCircleIcon fontSize="large" />,
      2: <CheckCircleIcon fontSize="large" />,
      3: <CheckCircleIcon fontSize="small" sx={{ marginLeft: "6px" }} />,
      4: <AdjustOutlinedIcon fontSize="large" />,
      5: <RadioButtonUncheckedIcon fontSize="small" sx={{ marginLeft: "6px" }} />,
      6: <RadioButtonUncheckedIcon fontSize="small" sx={{ marginLeft: "6px" }} />,
      7: <RadioButtonUncheckedIcon fontSize="large" />,
      8: <RadioButtonUncheckedIcon fontSize="small" sx={{ marginLeft: "6px" }} />,
      9: <RadioButtonUncheckedIcon fontSize="small" sx={{ marginLeft: "6px" }} />,
      10: <RadioButtonUncheckedIcon fontSize="small" sx={{ marginLeft: "6px" }} />,
      11: <RadioButtonUncheckedIcon fontSize="small" sx={{ marginLeft: "6px" }} />,
      12: <RadioButtonUncheckedIcon fontSize="small" sx={{ marginLeft: "6px" }} />,
      13: <RadioButtonUncheckedIcon fontSize="small" sx={{ marginLeft: "6px" }} />,
      14: <RadioButtonUncheckedIcon fontSize="small" sx={{ marginLeft: "6px" }} />,
    };

    let newArray = dataForms.filter((el) => {
      return el.group == listSteps[props.icon - 1].group && el.status == "Pending";
    });

    let idIcon;
    if (listSteps[props.icon - 1].type == "Child") {
      idIcon = 0;
    } else {
      if (listSteps[props.icon - 1].status == "Pending") {
        idIcon = 4;
      } else if (newArray.length <= 0) {
        idIcon = 1;
      } else {
        idIcon = 4;
      }
    }
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
          [classes.disabled]: disabled,
        })}
      >
        {stepIcons[idIcon]}
      </div>
    );
  };

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper className={classes.root} activeStep={activeStep} orientation="vertical">
        {listSteps?.map((step, index) => (
          <Step key={index}>
            <StepLabel
              StepIconComponent={CustomStepIcon}
              style={
                step.type == "Child"
                  ? {
                      borderLeft: "3px solid",
                      paddingLeft: "10px",
                      marginLeft: "15px",
                    }
                  : { fontWeight: "600" }
              }
            >
              <span className={step.type == "Child" ? "sub-step" : "step"}>{step.titleSteper}</span>
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepByStep;
