import React from "react";

import { Field } from "formik";

const Select = (props) => {
  let data = [];
  if (props.values[`data_${props.props.name}`]) {
    data = props.values[`data_${props.props.name}`];
  } else {
    data = props.data;
  }
  return (
    <Field
      name={props.props.name}
      as={props.props.type}
      placeholder={props.props.placeholder}
      onChange={async (e) => {
        const { value } = e.target;
        props.setFieldValue(props.props.name, value);
        props.onchange(props, value);
      }}
    >
      {data.map((op) => (
        <option key={op.id} value={op.value}>
          {op.label}
        </option>
      ))}
    </Field>
  );
};

export default Select;
