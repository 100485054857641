import React from "react";

import { Grid, Typography } from "@mui/material";

import Button from "../components/inputs/button";
import themeNotification from "../utils/themes/ThemeNotification";
import { primaryButtonStyles, setButtonLabel } from "../utils/themes/ThemePayment";

export default function Notification({
  image,
  animation,
  title,
  message,
  isAnimation = false,
  isError = false,
  button,
  goHome,
}) {
  let styles = themeNotification();

  const imageClass = () => {
    if (isAnimation) {
      return styles.animatedImage;
    }
    if (isError) {
      return styles.imageError;
    }
    return styles.staticImage;
  };

  const titleClass = () => {
    if (isError) {
      return styles.titleContainerError;
    }
    return styles.titleContainer;
  };

  return (
    <Grid container spacing={0} direction="column">
      <Grid item xs={12} className={imageClass()}>
        {animation}
        <img src={image} alt="notification" />
      </Grid>
      <Grid item xs={12} className={titleClass()}>
        <Typography>{title}</Typography>
      </Grid>
      <Grid item xs={12} className={styles.messageContainer}>
        <Typography className={styles.message}>{message}</Typography>
      </Grid>
      {button ? (
        <Grid item xs={12} className={styles.button}>
          <Button {...setButtonLabel(primaryButtonStyles, button)} btnNext={goHome} />
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
}
