import { React, useEffect, useState } from "react";

import TextField from "@material-ui/core/TextField";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import $ from "jquery";

import { ReactComponent as Greetings } from "../../assets/generic/Greetings.svg";
import { ReactComponent as Check } from "../../assets/icon/check.svg";
import { ReactComponent as Warning } from "../../assets/icon/warning.svg";

import Timer from "./timer";

import themeOtp from "../../utils/themes/ThemeOtp";

const LoginPinOPT = (otpData) => {
  const [pinOne, setPinOne] = useState();
  const [pinTwo, setPinTwo] = useState();
  const [pinThree, setPinThree] = useState();
  const [pinFour, setPinFour] = useState();
  const [borderFieldFilled, setBorderFieldFilled] = useState("");
  const theme = useTheme();
  const styles = themeOtp();
  const fieldProperties = {
    maxLength: 1,
    className: styles[borderFieldFilled],
  };

  useEffect(() => {
    if (pinOne || pinTwo || pinThree || pinFour) {
      setBorderFieldFilled("otpCodeBorderFieldFilled");
      otpData.setPinComplete(false);
    } else {
      setBorderFieldFilled("");
      otpData.setPinComplete(false);
    }
    if (pinOne && pinTwo && pinThree && pinFour) {
      otpData.setOTP(`${pinOne}${pinTwo}${pinThree}${pinFour}`);
      otpData.setPinComplete(true);
    }
  }, [pinOne, pinTwo, pinThree, pinFour]);

  const resetMessage = (message, telephone, currency) => {
    let temTelephone = telephone && telephone.slice(Math.max(telephone.length - 2, 0));
    telephone = `+${currency}*******${temTelephone ? temTelephone : "*"}`;
    return message && message.replace("{{telephone}}", telephone);
  };

  const getTelephone = () => {
    const activeCellNumber0 = otpData.temporalData[0]?.active_cell_number;
    const activeCellNumber1 = otpData.temporalData[1]?.active_cell_number;

    return activeCellNumber0 !== undefined ? activeCellNumber0 : activeCellNumber1;
  };

  const sizesGrid = {
    titleOtptrue: otpData.props?.titleMobile,
    subTitleOtptrue: resetMessage(otpData.props?.subtitleMobile, getTelephone(), otpData.countryCode),
    errorBoxtrue: 12,
    pinBoxErrortrue: "red",
    pinBoxErrorfalse: "gray",
    titleOtpfalse: otpData.props?.title,
    subTitleOtpfalse: otpData.props?.subtitle,
    errorBoxfalse: 8,
  };
  const sizeGridController = (type, xs) => {
    let key = type + xs.toString();
    return sizesGrid[key];
  };

  let matches = useMediaQuery(theme.breakpoints.down("sm"));

  const setPinValues = (setValue, e) => {
    const { value } = e.target;
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (typeof onlyNums === "number") {
      setValue(value);
    } else {
      setValue(onlyNums);
    }
  };

  const autoTab = (e) => {
    const BACKSPACE_KEY = 8;
    const DELETE_KEY = 46;
    let tabindex = $(e.target).attr("tabindex");
    tabindex = Number(tabindex);
    if (e.keyCode === BACKSPACE_KEY) {
      tabindex -= 1;
    } else if (e.keyCode !== DELETE_KEY) {
      tabindex += 1;
    }
    const elem = $(".otp-tab").find("[tabindex=" + tabindex + "]");
    if (elem[0]) {
      elem.trigger("focus");
    }
  };

  return (
    <Grid container className={styles.banner}>
      <Grid
        container
        className={styles.boxPrincipal}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12}>
          <Greetings className={styles.contentLogo} />
        </Grid>
        <Grid item xs={12}>
          <p className={styles.titleOtp}>{sizeGridController("titleOtp", matches)}</p>
        </Grid>

        <Grid item xs={12} className={styles.subTitleOtpExtra}>
          <p className={styles.subTitleOtp}>{sizeGridController("subTitleOtp", matches)}</p>
        </Grid>

        <Grid item xs={12}>
          <p className={styles.messageOtp}>{otpData.props?.messageMobile}</p>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          className="otp-tab"
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            container
            className={styles.pinOtpBoxes}
            spacing={1}
            sx={{
              // maxWidth: "997.5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "25px",
            }}
          >
            <Grid item sx={2}>
              <TextField
                style={{
                  borderColor: sizeGridController("pinBoxError", otpData.messageOTP?.icon === "warning"),
                }}
                id="pin1"
                onChange={async (e) => {
                  setPinValues(setPinOne, e);
                }}
                value={pinOne}
                variant="outlined"
                className={styles.TextFieldOTP}
                inputProps={{ ...fieldProperties, tabIndex: "0" }}
                onKeyUp={autoTab}
              />
            </Grid>
            <Grid item sx={2}>
              <TextField
                style={{
                  borderColor: sizeGridController("pinBoxError", otpData.messageOTP?.icon === "warning"),
                }}
                id="pin2"
                onChange={async (e) => {
                  setPinValues(setPinTwo, e);
                }}
                value={pinTwo}
                variant="outlined"
                className={styles.TextFieldOTP}
                inputProps={{ ...fieldProperties, tabIndex: "1" }}
                onKeyUp={autoTab}
              />
            </Grid>
            <Grid item sx={2}>
              <TextField
                style={{
                  borderColor: sizeGridController("pinBoxError", otpData.messageOTP?.icon === "warning"),
                }}
                id="pin3"
                onChange={async (e) => {
                  setPinValues(setPinThree, e);
                }}
                value={pinThree}
                variant="outlined"
                className={styles.TextFieldOTP}
                inputProps={{ ...fieldProperties, tabIndex: "2" }}
                onKeyUp={autoTab}
              />
            </Grid>
            <Grid item sx={2}>
              <TextField
                style={{
                  borderColor: sizeGridController("pinBoxError", otpData.messageOTP?.icon === "warning"),
                }}
                id="pin4"
                onChange={async (e) => {
                  setPinValues(setPinFour, e);
                }}
                value={pinFour}
                variant="outlined"
                className={styles.TextFieldOTP}
                inputProps={{ ...fieldProperties, tabIndex: "3" }}
                onKeyUp={autoTab}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box className={styles.TextTimerOTP}>
              {otpData.messageOTP?.icon === "" && (
                <Timer props={otpData} initialText={otpData.props?.initialText} endText={otpData.props?.endText} />
              )}
            </Box>
          </Grid>
          <Grid item xs={sizeGridController("errorBox", matches)} sx={{ display: otpData.messageOTP?.display }}>
            <Grid container className={styles.ContentError}>
              <Grid item xs={1}>
                {otpData.messageOTP?.icon === "warning" && <Warning sx={{ display: "flex" }} />}
                {otpData.messageOTP?.icon === "check" && <Check sx={{ display: "flex" }} />}
              </Grid>
              <Grid item xs={11} sx={{ textAlign: "start !important" }}>
                <p> {otpData.messageOTP?.message}</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginPinOPT;
