import Cedula from "./inputs/cedula";
import CheckBox from "./inputs/checkbox";
import Code from "./inputs/code";
import Column from "./inputs/column";
import DateField from "./inputs/date";
import DateLegalField from "./inputs/dateLegalField";
import Label from "./inputs/label";
import LoginPinOPT from "./inputs/loginPinOPT";
import Numeric from "./inputs/numeric";
import Radiobutton from "./inputs/radioButton";
import Ruc from "./inputs/ruc";
import RucFormatField from "./inputs/rucFormatField";
import RucWithContributionFieldValidation from "./inputs/rucWithContributionFieldValidation";
import Select from "./inputs/select";
import SelectMaterial from "./inputs/selectMaterial";
import SelectWithFormikEmoney from "./inputs/selectWithFormikEmoney";
import SelectWithFormikEpin from "./inputs/selectWithFormikEpin";
import Telephone from "./inputs/telephone";
import Text from "./inputs/text";
import TextFieldMaterial from "./inputs/textFieldMaterial";
import TextFieldPrefilled from "./inputs/textFieldPrefilled";
import ToggleButton from "./inputs/toggleButton";
import UploadFile from "./inputs/uploadFileArea";
import UploadFileCard from "./inputs/uploadFileCard";
import UploadFileCardV2 from "./inputs/uploadFileCardV2";

const inputsComponents = {
  telephone: Telephone,
  text: Text,
  select: Select,
  column: Column,
  code: Code,
  numeric: Numeric,
  radiobutton: Radiobutton,
  checkbox: CheckBox,
  date: DateField,
  uploadfile: UploadFile,
  uploadfilecard: UploadFileCard,
  uploadfilecardV2: UploadFileCardV2,
  textField: TextFieldMaterial,
  textFieldPrefilled: TextFieldPrefilled,
  ruc: Ruc,
  cedula: Cedula,
  label: Label,
  selectMaterial: SelectMaterial,
  loginPinOPT: LoginPinOPT,
  toggleButton: ToggleButton,
  dateLegal: DateLegalField,
  rucFormat: RucFormatField,
  rucWithContributionFieldValidation: RucWithContributionFieldValidation,
  selectWithFormikEpin: SelectWithFormikEpin,
  selectWithFormikEmoney: SelectWithFormikEmoney,
};

const Input = ({ type, data }) => {
  let InputsComponent = inputsComponents[[type]];

  if (type === "uploadfileCard");

  if (type === "uploadfileCardV2");

  if (!InputsComponent) {
    return null;
  }
  return <InputsComponent key={data.id} {...data} />;
};

const GenericInput = (props) => {
  return <Input type={props.props.type} data={props} />;
};

export default GenericInput;
