import React from "react";

import themeKym from "../../utils/themes/ThemeKym";

const Label = (data) => {
  const classesKym = themeKym();
  const classes = data.props.classes ? data.props.classes : {};
  const temporalData = { ...data.temporalData[0], ...data.temporalData[1] };
  const name = data.props.name;
  const value = temporalData[name];

  return value ? (
    <>
      <label data-testid={data.id} className={classesKym[`${classes.title}`]}>
        {data.props.label}
      </label>
      <label className={classesKym[`${classes.value}`]}>{value}</label>
    </>
  ) : (
    <></>
  );
};

export default Label;
