import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useField } from "formik";

import themeKym from "../../utils/themes/ThemeKym";

const SelectWithFormikEpin = (data) => {
  const { data: dataParams, props: params, selectItems, errorEpin, setErrorEpin } = data;

  const [actualValue, setActualValue] = useState(params.value);
  const [listItems, setListItems] = useState([]);
  const [field] = useField(params.name);
  const classesKym = themeKym();

  const configTextField = {
    ...field,
    placeholder: params.placeholder,
    label: params.label,
    id: params.id,
    title: params.title,
    name: params.name,
  };

  const handleFunction = (e) => {
    const { value } = e.target;
    setActualValue(value);
    data.setFieldValue(params.name, value);
    data.onchange(data, value);
    setErrorEpin(value === "No aplica");
  };

  const buildListItems = () => {
    const listType = selectItems[`${params.listType}`];
    setListItems(listType.concat(dataParams));
  };

  useEffect(async () => {
    buildListItems();
  }, [selectItems]);

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel className={classesKym[`${params.classes.label}`]}>{params.label}</InputLabel>
        <Select
          {...configTextField}
          value={actualValue}
          className={classesKym[`${params.classes.select}`]}
          onChange={handleFunction}
        >
          {listItems.map((op) => (
            <MenuItem key={op.id} className={classesKym[`${params.classes.menuItem}`]} value={op.defaulValue}>
              {op.label}
            </MenuItem>
          ))}
        </Select>
        <Grid
          style={{
            color: "red",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "justify",
            display: errorEpin ? "block" : "none",
          }}
        >
          Debes de seleccionar un valor valido de la lista.
        </Grid>
      </FormControl>
    </Box>
  );
};

export default SelectWithFormikEpin;
