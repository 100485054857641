import React from "react";

import { Button, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ButtonsOTP = ({ btn, btnNext, btnResendOTP, messageOTP, pinComplete }) => {
  const valideButton = btn.buttons[0];
  const resendButton = btn.buttons[1];
  const getClasses = (btn) => {
    if (btn.styleClassName) {
      let _class = [];
      _class.push(btn.styleClassName);
      return _class.join(" ").trim();
    }

    return "merchant-primary";
  };
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const hidde = { display: "none" };
  const show = undefined;

  const theresError = () => {
    return messageOTP?.type == "fatal";
  };

  const disable = () => {
    return !pinComplete || messageOTP?.icon === "check";
  };

  const showValidate = () => {
    if (!isMobile) {
      return show;
    }
    if (theresError()) {
      return hidde;
    }
    return show;
  };
  const showError = () => {
    if (!isMobile) {
      return show;
    }
    if (!theresError()) {
      return hidde;
    }
    return show;
  };

  return (
    <Grid container direction="row" justifyContent="center">
      <Grid item xs={10} sm={5} sx={showValidate()}>
        <Button
          color="secondary"
          variant="contained"
          fullWidth
          className={getClasses(valideButton)}
          type={valideButton.type}
          name={valideButton.name}
          onClick={btnNext}
          style={valideButton.styles}
          disabled={disable()}
        >
          {valideButton.label}
        </Button>
      </Grid>
      <Grid item xs={10} sm={5} sx={showError()}>
        <Button
          color="secondary"
          variant="contained"
          fullWidth
          className={getClasses(resendButton)}
          type={resendButton.type}
          name={resendButton.name}
          onClick={btnResendOTP}
          style={resendButton.styles}
        >
          {resendButton.label}
        </Button>
      </Grid>
    </Grid>
  );
};
export default ButtonsOTP;
