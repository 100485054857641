import { Fragment, useEffect, useState } from "react";

import { Box, FormHelperText, Grid, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import axios from "axios";

import { ReactComponent as CheckCircle } from "../../assets/icon/checkCircleIcon.svg";
import { ReactComponent as EditPencil } from "../../assets/icon/editPencilIcon.svg";
import { ReactComponent as UploadButton } from "../../assets/icon/uploadButton.svg";
import { ReactComponent as UploadFile } from "../../assets/icon/uploadFileIcon.svg";
import { ReactComponent as WrongCircleIcon } from "../../assets/icon/wrongCircleIcon.svg";

import axiosClient from "../../actions/axiosClient";
import themeKym from "../../utils/themes/ThemeKym";

export default function UploadFileCardV2(data) {
  const [file, setFile] = useState(data.fileLoaded);
  const classesKym = themeKym();
  const [error, setError] = useState(false);
  const [fileErrorText, setFileErrorText] = useState("");

  function internalOnChange(event) {
    setFile(null);
    data.setFieldValue(data.props.name, event.currentTarget.files[0]);
    saveFileV2(event.target.files[0]);
  }

  const saveFileV2 = (fileData) => {
    let fileExt = fileData.type?.split("/")[1].toLowerCase();
    let validExt = ["pdf", "png", "jpeg"];
    if (fileData.size > 10 ** 7) {
      setFileErrorText("Peso máximo de archivo de 10MB. Sube un archivo más pequeño.");
      setError(true);
      return;
    }
    if (!validExt.includes(fileExt)) {
      setFileErrorText("Formato de archivo inválido. Solo se admiten jpg, png y pdf.");
      setError(true);
      return;
    }
    let requestDataFile = {
      folder: `${data.msisdn ? data.msisdn : data.idpdv}`,
      filename: `${data.props.name?.toLowerCase()}.${fileExt === "jpeg" ? "jpg" : fileExt}`,
    };

    axiosClient
      .post(`/user/filesUpload`, { ...requestDataFile })
      .then((uploadResponse) => {
        let urlResp = uploadResponse.data.url;
        let fieldsResp = uploadResponse.data.fields;
        let formData = new FormData();
        Object.entries(fieldsResp).forEach(([k, v]) => {
          formData.append(k, v);
        });

        formData.append("file", fileData, fileData.name.toLowerCase());

        let instance = axios.create({
          headers: {
            post: {
              "Content-Type": "multipart/form-data",
            },
          },
        });
        delete instance.defaults.headers.common["Authorization"];
        instance
          .post(urlResp, formData)
          .then((resp) => {
            console.log("Revisa el response de la instancia: ", resp);
            setFile(fileData);
            setFileErrorText("");
            setError(false);
          })
          .catch((error) => {
            console.log("Error - instancia", error);
          });
      })
      .catch((error) => {
        console.log("error - upload response", error);
      });
  };

  useEffect(() => {
    if (data.fileLoaded && data.temporalData[3] !== undefined && data.values[data.props.name] === "") {
      data.setFieldValue(data.props.name, data.temporalData[3][data.props.name]);
    }
  });

  if (data.props.disabled) {
    return <></>;
  }
  return (
    <Grid container>
      <Grid item xs={12} style={{ flex: 1 }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "32px",
            float: "left",
            color: "#4C5866",
            textAlign: "left",
          }}
        >
          {data.props?.title}
        </Typography>
      </Grid>
      {(data.props?.reasonRejected || data.props?.otherReason) && (
        <Grid item xs={12}>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "32px",
              float: "left",
              color: "#00377D",
              textAlign: "left",
              marginRight: "5px",
            }}
          >
            {"Motivo del rechazo: "}
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "32px",
              float: "left",
              color: "#00377D",
              textAlign: "left",
            }}
          >
            {`${data.props?.reasonRejected} ${data.props?.otherReason}`}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} className={classesKym.CardContainer}>
        <Box className={classesKym.UploadFileCardContainerV2}>
          <Fragment>
            <Grid
              container
              style={{
                textAlign: "center",
                position: "relative",
                height: "100%",
              }}
            >
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="center"
                className={classesKym.CardContainerCard}
              >
                {/* grid - section icon */}
                <Grid item xs={2} sm={2} className={classesKym.CardContainerIcon}>
                  {!file && !error && <UploadFile />}
                  {error && (
                    <WrongCircleIcon
                      style={{
                        fontSize: "20px",
                      }}
                    />
                  )}
                  {file && (
                    <CheckCircle
                      style={{
                        fontSize: "20px",
                      }}
                    />
                  )}
                </Grid>

                {/* grid - section text */}
                <Grid item xs={7} sm={7}>
                  {file ? (
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom component="div" className={classesKym.CardContainerText}>
                          <span>Archivo cargado correctamente</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="h5"
                          gutterBottom
                          component="div"
                          sx={{
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "18px",
                            letterSpacing: "0.247px",
                            color: "#404A56",
                            float: "left",
                            textAlign: "initial",
                          }}
                        >
                          {data.props?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom component="div" className={classesKym.CardContainerText}>
                          {error ? (
                            <span>Se produjo un error</span>
                          ) : (
                            <div>
                              <span class="web">Arrastra tu documento aquí</span>
                              <span class="mobile">Sube tu documento</span>
                            </div>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="h5"
                          gutterBottom
                          component="div"
                          className={classesKym.CardContainerSubText}
                        >
                          {!error ? <span>o búscalo en tu computadora haciendo click en ‘Subir archivo’.</span> : null}
                          <FormHelperText error="true">{fileErrorText}</FormHelperText>
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                {/* grid - section buton load file */}
                <Grid item xs={3} sm={3} style={{ position: "relative" }}>
                  {!file ? (
                    <Typography
                      variant="h5"
                      gutterBottom
                      component="div"
                      className={classesKym.CardContainerButtonUpload}
                    >
                      <IconButton color="primary" aria-label="upload picture" component="label">
                        <UploadButton />
                      </IconButton>
                      <a href="# ">Subir archivo</a>
                    </Typography>
                  ) : (
                    <Typography
                      variant="h5"
                      gutterBottom
                      component="div"
                      className={classesKym.CardContainerButtonReplace}
                    >
                      <a href="# ">
                        <EditPencil /> <span>Reemplazar</span>
                      </a>
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <input
                data-testid={"InputFile"}
                id={data.props.name}
                required={!file}
                type="file"
                accept="image/*,application/pdf"
                style={{
                  position: "absolute",
                  margin: "0",
                  padding: "0",
                  width: "100%",
                  height: "100%",
                  opacity: "0",
                  outline: "none",
                  cursor: "pointer",
                  left: 0,
                  top: 0,
                }}
                onChange={internalOnChange}
              />
            </Grid>
          </Fragment>
        </Box>
      </Grid>
    </Grid>
  );
}
