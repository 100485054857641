import React from "react";

import { Button } from "@mui/material";
import { useField } from "formik";

/**
 *  show => mark if the form is open o close
 *  name => Its name of section
 *  btnHiden => function for close and reset form
 *  btnShow => function for open form
 *  props => basic data for button
 *  touched => review if is the first register
 *  submitForm=> validate inpust in data
 *  resetForm => use for empty inputs
 */

const ButtonAdd = ({ show, btnHiden, btnShow, props, touched, submitForm, resetForm }) => {
  const [field] = useField(props.name);

  const onclick = async (e) => {
    if (show) {
      if (await submitForm()) btnHiden(resetForm);
    } else {
      btnShow();
    }
    field.onChange(e);
  };

  let style = { marginTop: "20px" };

  const getValuesLabel = () => {
    if (show) {
      return props.values[2];
    }
    if (touched) return props.values[1];

    return props.values[0];
  };

  return (
    <Button
      color="primary"
      variant="contained"
      fullWidth
      className="merchant-primary"
      type={"button"}
      name={props.name}
      onClick={(e) => onclick(e)}
      style={style}
    >
      {getValuesLabel()}
    </Button>
  );
};
export default ButtonAdd;
