import axiosClient from "./axiosClient";
import axiosClientMwc from "./axiosClientMwc";

export const insertUser = async (data, module = false) => {
  if (module == "Working-Capital") {
    data["module"] = module;
  }
  return axiosClient
    .post(`${process.env.REACT_APP_DOMAIN}/user`, { ...data })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return err.response;
    });
};

export const updateUser = async (data, email) => {
  return axiosClient
    .put(`${process.env.REACT_APP_DOMAIN}/user?email=${email}`, { ...data })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return err.response;
    });
};
export const valideMSISDN = async (msisdn_) => {
  return axiosClientMwc
    .get(`${process.env.REACT_APP_DOMAIN_MWC}/msisdn/${msisdn_}`)
    .then((response) => {
      return response;
    })
    .catch((_err) => {
      return _err.response;
    });
};
