const countrycode = {
  py: "595",
  hn: "504",
  sv: "503",
  bo: "591",
  pa: "507",
};

const getCountryCode = (country) => {
  return countrycode[country];
};
export { getCountryCode };
